import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

// Routes
import pages from './routes/pages'
import buro from './routes/buro'
import cari from './routes/cari'
import stok from './routes/stok'
import fatura from './routes/fatura'
import gider from './routes/gider'
import depo from './routes/depo'
import servis from './routes/servis'
import kasa from './routes/kasa'
import banka from './routes/banka'
import rapor from './routes/rapor'
import ihale from './routes/ihale'
import salon from './routes/salon'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: process.env.NODE_ENV === 'production'
        ? 'hash'
        : 'history',
    base: '/',
    scrollBehavior() {
        return {
            x: 0,
            y: 0,
        }
    },
    routes: [
        {
            path: '/',
            redirect: { name: 'dashBoard' },
        },
        ...salon,
        ...ihale,
        ...rapor,
        ...banka,
        ...kasa,
        ...servis,
        ...depo,
        ...gider,
        ...fatura,
        ...stok,
        ...cari,
        ...buro,
        ...pages,
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

router.beforeEach(async (to, from, next) => {
    const isLoggedIn = store.getters.isAuthenticated
    if (to.path === '/login' || to.path === '/expariedLogin') {
        if (isLoggedIn) {
            return next(false)
        }
        return next()
    }
    if (!isLoggedIn) {
        try {
            await store.dispatch('authControl')
            return next()
        } catch (error) {
            return next({ name: 'login' })
        }
    }
    return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        setTimeout(() => {
            appLoading.style.display = 'none'
        }, 500)
    }
})

export default router
